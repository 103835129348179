<template>
  <main>
    <section
      class="hero d-flex flex-column justify-content-start align-items-center"
      :style="{
        background: heroImg === '' ? '' : `url(${require(`@/assets/img/hero/${heroImg}.jpeg`)})`,
        filter: `blur(${blur}px)`
      }"
      data-cy="hero"
    >
      <XmasNotice v-if="false" />
      <Announcement v-if="true" />
      <header>
        <b-alert
          v-for="(alert, ix) in alerts"
          class="mb-4 text-white bg-transparent border rounded-0 w-100"
          :key="`alert-${ix}`"
          show
        >
          <h4 v-text="alert.title" />
          <div v-html="alertText(alert.text)" />
        </b-alert>
        <h1 class="mx-3" style="white-space: pre-wrap;">{{title}}</h1>
        <h4 class="mx-3" v-if="description !== ''">{{description}}</h4>
        <ScrollHint class="mb-5" :theme="theme" />
      </header>
    </section>
    <section class="overlay">
      <Overlay
        :benefits="productContent ? productContent.benefits : []"
        :imgDt="productContent ? productContent.overlayImg.dt : 'ITC_car'"
        :imgMob="productContent ? productContent.overlayImg.mob : 'ITC_car'"
        data-cy="overlay"
      >
        <slot name="overlay-top-extra" />
      </Overlay>
      <slot name="overlay-info" />
      <Contact />
    </section>
  </main>
</template>

<script>
import { throttle } from 'lodash';
import DOMPurify from 'dompurify';
import Contact from '@/components/Contact/Contact.vue';
import XmasNotice from '@/components/Notice/XmasNotice.vue';
import Announcement from '@/components/Notice/Announcement.vue';
import Overlay from './Overlay/Overlay.vue';
import ScrollHint from './ScrollHint/ScrollHint.vue';

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
});

export default {
  data() {
    return {
      blur: '0px',
      setHeroBlurThrottled: throttle(this.setHeroBlur, 33),
    };
  },
  props: {
    title: {
      type: String,
      default: 'Placeholder Title',
    },
    alerts: {
      type: Array,
      default: () => ([]),
    },
    description: {
      type: String,
      default: '',
    },
    heroImg: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'primary',
    },
    productContent: {
      type: Object,
      required: false,
    },
  },
  components: {
    XmasNotice,
    Announcement,
    Overlay,
    ScrollHint,
    Contact,
  },
  methods: {
    setHeroBlur() {
      if (window.pageYOffset <= window.innerHeight) {
        // Scaling value between 0-1, 0 for the top 80 pixels to prevent immediately going blurry
        this.blur = Math.min(Math.max(window.pageYOffset - 80, 0) / window.innerHeight, 1) * 16;
      }
    },
    alertText(markup) {
      return DOMPurify.sanitize(markup);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.setHeroBlurThrottled);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.setHeroBlurThrottled);
  },
};
</script>

<style lang="scss" scoped src="./PageContent.scss"></style>
