<template>
  <b-container
    class="notice text-center"
    fluid
    @click="expanded = !expanded"
  >
    <b-container class="py-3">
      <b-row class="justify-content-center">
        <b-col cols="auto">
          <h2 class="notice__header mb-0 px-3">
            Christmas Opening Hours
          </h2>
          <b-collapse
            v-model="expanded"
            class="notice__collapse mx-auto"
          >
            <b-row class="py-3 justify-content-center">
              <b-col
                class="notice__collapse__col"
                cols="auto"
              >
                <b-row>
                  <b-col cols="auto">
                    <div
                      v-for="day of days"
                      :key="day.date"
                      v-text="day.date"
                    />
                  </b-col>
                  <b-col cols="auto">
                    <div
                      v-for="day of days"
                      :key="`${day.date}-times`"
                      v-text="day.times"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>
    <Arrow
      :class="`notice__collapse-arrow${expanded ? '--active' : ''}`"
      direction="down"
      theme="white"
    />
  </b-container>
</template>

<script>
import Arrow from '@/components/Arrow/Arrow.vue';

export default {
  components: {
    Arrow,
  },
  data: () => ({
    days: [
      { date: 'Dec 24', times: '9am - 2pm' },
      { date: 'Dec 25', times: 'Closed' },
      { date: 'Dec 26', times: 'Closed' },
      { date: 'Dec 27', times: '9am - 3pm' },
      { date: 'Dec 30', times: '9am - 3pm' },
      { date: 'Dec 31', times: '9am - 3pm' },
      { date: 'Jan 1', times: 'Closed' },
    ],
    expanded: true,
  }),
};
</script>

<style lang="scss" scoped src="./XmasNotice.scss"></style>
